import React from "react";
import { graphql, withPrefix } from "gatsby";
import Page from "../components/templates/Page";
import Header from "../components/blocks/Header/";
import { ColorTheme } from "../components/themes/ColorTheme";
import Section from "../components/elements/Section";
import SEO from "../components/elements/SEO";
import Button from "../components/elements/Button";
import Text from "../components/blocks/Text/";
import Footer from "../components/elements/Footer";

const errorPage = ({ data }) => {
  const { title } = data.site.siteMetadata;

  return (
    <Page>
      <ColorTheme color="black">
        <Header siteName={title} siteNameLink={withPrefix("/")} takesSpace />
      </ColorTheme>

      <ColorTheme color="green">
        <main>
          <Section>
            <Text>
              <h1>Tätä sivua ei ole olemassa.</h1>
              <p>
                Tällä osoitteella ei löydy sivua, mutta tulit varmasti
                tutustumaan Kiilakortteliin. Tervetuloa!
              </p>
              <p>
                <Button to="/">Etusivulle</Button>
              </p>
            </Text>
          </Section>
        </main>
      </ColorTheme>

      <Footer />

      <SEO title="Sivua ei löytynyt" />
    </Page>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default errorPage;
